import React, { memo } from 'react'
// @TODO: Remove if earn vir via x stopped
// import { BWPanelContent, BWText } from 'src/libs/qb-brand-web-components'

// import { EARN_VIA_X } from '../../../constants/navigation'
// import NavButton from '../../../components/shared/button/NavButton'

const HowToEarnRewardsWrapper = () =>
  // { t }
  {
    return <></>
    // return (
    //   <BWPanelContent>
    //     <BWText label={t('brand:earn-vir.earn-via-x-desc')} alignCenter />
    //     <NavButton label={t('brand:earn-vir.earn-via-x')} to={EARN_VIA_X} />
    //   </BWPanelContent>
    // )
  }

export default memo(HowToEarnRewardsWrapper)
