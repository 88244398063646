import React, { memo } from 'react'
import {
  RainbowKitProvider,
  cssStringFromTheme,
  lightTheme
} from '@rainbow-me/rainbowkit'
import { WagmiProvider } from 'wagmi'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'

import brandConfig from 'brandConfig'
import { getChain, wagmiConfig } from './wagmiConfig'

const queryClient = new QueryClient()

const WalletConnectWrapper = ({ children }) => {
  const customTheme = lightTheme({
    accentColor: brandConfig.theme.colors.accent,
    fontStack: 'system'
  })
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={null} initialChain={getChain()}>
          <style
            dangerouslySetInnerHTML={{
              __html: `
            :root {
              ${cssStringFromTheme(customTheme)}
            }
          `
            }}
          />
          {children}
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  )
}

export default memo(WalletConnectWrapper)
