import React, { memo } from 'react'
import {
  BWPanel,
  BWText,
  BWPanelContent,
  openExternalUrl
} from 'src/libs/qb-brand-web-components'

import Button from '../../../../../components/shared/button/Button'

const GetFounderKey = ({ t }) => {
  return (
    <BWPanel>
      <BWPanelContent>
        <BWText
          label={t('brand:wallet.get-founders-key')}
          size={18}
          weight={700}
          alignCenter
        />
      </BWPanelContent>
      <Button
        label={'opensea'}
        onClick={() =>
          openExternalUrl('https://opensea.io/collection/qbx-genesis-nft')
        }
      />
      <Button
        label={'blur'}
        onClick={() =>
          openExternalUrl('https://blur.io/eth/collection/qbx-genesis-nft')
        }
      />
    </BWPanel>
  )
}

export default memo(GetFounderKey)
