export const VIR_PER_STAKED_QBX = 1000

export const CURRENCIES = {
  QBX: 'QBX',
  VIR: 'VIR'
}

export const VIR_TO_USD_CONVERSION_RATE = 0.05 // USD

export const QBX_TO_VIR_CONVERSION_RATE = 0.0033 // VIR

export const MEMBER_TIERS_KEY = {
  ASPIRING: 'aspiring',
  FAN: 'fan',
  INFLUENCER: 'influencer',
  CHAMPION: 'champion',
  AMBASSADOR: 'ambassador',
  HONORARY: 'honorary'
}

export const MEMBER_TIERS = {
  [MEMBER_TIERS_KEY.ASPIRING]: 1,
  [MEMBER_TIERS_KEY.FAN]: 1.05,
  [MEMBER_TIERS_KEY.INFLUENCER]: 1.1,
  [MEMBER_TIERS_KEY.CHAMPION]: 1.15,
  [MEMBER_TIERS_KEY.AMBASSADOR]: 1.2,
  [MEMBER_TIERS_KEY.HONORARY]: 1.25
}

export const FOUNDER_KEYS = {
  '1-5': 0.05,
  '6-20': 0.1,
  '21-50': 0.15,
  '51-100': 0.2,
  '100+': 0.25
}
