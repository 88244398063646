import { BW_LANGUAGE_EN } from 'src/libs/qb-brand-web-components'

const brandConfig = {
  brandId: 'qbx_vip',
  name: 'QBX VIP Club',
  faqEmail: 'foundation@qiibee.com',
  websiteUrl: 'https://qiibeefoundation.org/qbx-vip-club/',
  termsUrl: 'https://static.qiibee.com/rewards/terms.pdf',
  privacyPolicyUrl: 'https://www.qiibee.com/privacy-policy/',
  cookiePolicyUrl: 'https://static.qiibee.com/rewards/cookiepolicy.pdf',
  theme: {
    colors: {
      main: '#2d2d2d',
      accent: '#000000'
    }
  },
  supportedLanguages: [BW_LANGUAGE_EN],
  features: {
    enableImpactReport: true,
    enableRootImpactReport: true,
    homeBanners: true,
    staking: false // @TODO: Need to enable when staking is ready
  },
  cognito: {
    region: 'eu-central-1',
    poolId: 'eu-central-1_6bu4ynztS',
    clientId: 'f5j7n0vp8lsblqltvjkhor832',
    poolDomain: 'qbxvip.auth.eu-central-1.amazoncognito.com',
    redirectUri: 'https://vip.qiibeefoundation.org/login'
  },
  gaTrackingId: 'G-VSXV21SVWW',
  hotjarId: '3125123'
}

export default brandConfig
