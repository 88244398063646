import { VIP_EARN_ACTION } from '../../constants/actions'

const getXProfile = () => ({
  type: VIP_EARN_ACTION.GET_PROFILE
})

const disconnectXAccount = () => ({
  type: VIP_EARN_ACTION.DISCONNECT_ACCOUNT
})

const connectXAccount = () => ({
  type: VIP_EARN_ACTION.CONNECT_ACCOUNT
})

const resetEarnProfile = () => ({
  type: VIP_EARN_ACTION.RESET_STATE
})

const getBannersGroupingData = () => ({
  type: VIP_EARN_ACTION.GET_BANNERS_GROUPING_DATA
})

export {
  getXProfile,
  disconnectXAccount,
  connectXAccount,
  resetEarnProfile,
  getBannersGroupingData
}
