import React, { memo } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import {
  CSS_GRID,
  CSS_ALIGN_ITEMS_CENTER,
  CSS_JUSTIFY_CONTENT_CENTER
} from 'src/libs/qb-brand-web-components'

const ProviderIcon = ({ icon, ...restProps }) => {
  return (
    <ProviderIconCircle data-testid='exchange-provider-icon' {...restProps}>
      {icon && icon}
    </ProviderIconCircle>
  )
}

export default memo(ProviderIcon)

const ProviderIconCircle = styled.div`
  ${CSS_GRID};
  ${CSS_ALIGN_ITEMS_CENTER};
  ${CSS_JUSTIFY_CONTENT_CENTER};
  border-radius: 50%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-origin: content-box;
  ${(props) => circleIconStyles(props)};
`

const circleIconStyles = ({
  theme: { colors },
  color,
  bgImage,
  noMargin,
  small,
  customCss
}) => {
  const iconSize = small ? 60 : 80
  return css`
    height: ${iconSize}px;
    width: ${iconSize}px;
    border: 1px solid ${colors.liteWhite};
    background-color: ${color ? colors[color] : colors.liteWhite};
    ${bgImage && `background-image: url(${bgImage});`}
    ${!noMargin && 'margin-bottom: 5px;'}
    ${customCss && customCss}
  `
}
