import { deepMerge } from 'src/libs/qb-brand-web-components'

import defaultConfig from '../common/customConfig'
import { EARN_REWARD_OPTION } from '../../constants/pointsExchange'

const customConfig = {
  howManyPointsYouEarnLabel: 'brand:reward.how-many-points-you-earn',
  buyPointsDesc: 'brand:reward.buy-points-desc',
  cashbackDesc: 'brand:reward.cashback-desc',
  rewardOptionsOrder: [
    EARN_REWARD_OPTION.CASHBACK,
    EARN_REWARD_OPTION.BUY_POINTS
  ]
}

export default deepMerge(defaultConfig, customConfig)
