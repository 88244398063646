import React, { memo } from 'react'
// import { BWText } from 'src/libs/qb-brand-web-components'

import ProviderIcon from '../../../../../components/shared/exchangeProviderCard/ProviderIcon'
import ProviderCard from '../../../../../components/shared/exchangeProviderCard/ProviderCard'
import {
  buildFounderKeyLink,
  nftIPFSImageUrl
} from '../../utils/wallet.helpers'
import { openBlankRedirectPage } from '../../../../../util/app/app.helpers'

const NftCard = ({ name, id, image }) => {
  return (
    <ProviderCard
      // label={t('brand:wallet.nft-name', { value: 1, name })}
      label={name}
      // subLabel={
      //   <BWText
      //     label={t('brand:wallet.multiplier-per-nft', { value: 1.2 })}
      //     size={14}
      //   />
      // }
      icon={
        <ProviderIcon
          bgImage={nftIPFSImageUrl(image)}
          noMargin
          customCss={{ height: 56, width: 56, borderRadius: 5 }}
        />
      }
      anchor
      labelSize={16}
      labelGap={5}
      onClick={() => openBlankRedirectPage(buildFounderKeyLink(id))}
    />
  )
}

export default memo(NftCard)
