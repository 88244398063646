// @TODO: Enable when wallet connection is ready
import React, { memo, useEffect } from 'react'
import { useDisconnect } from 'wagmi'

import AuthorizedLayout from '../../../components/authorizedLayout/AuthorizedLayout'
import PoweredByWrapper from 'wrappers/poweredBy/PoweredByWrapper'
import WalletConnectWrapper from './utils/WalletConnectWrapper'
import { disconnectMetamaskWallet } from './utils/staking.helpers'

const AuthorizedLayoutWrapper = (props) => {
  return (
    <WalletConnectWrapper>
      <AuthLayout {...props} />
    </WalletConnectWrapper>
  )
}

export default memo(AuthorizedLayoutWrapper)

const AuthLayout = ({ initSignOut, ...restProps }) => {
  const { disconnect } = useDisconnect()
  useEffect(() => {
    if (initSignOut) {
      disconnect()
      disconnectMetamaskWallet()
    }
  }, [initSignOut])
  return <AuthorizedLayout poweredBy={<PoweredByWrapper />} {...restProps} />
}

// import React, { memo } from 'react'

// import AuthorizedLayout from '../../../components/authorizedLayout/AuthorizedLayout'
// import PoweredByWrapper from 'wrappers/poweredBy/PoweredByWrapper'

// const AuthorizedLayoutWrapper = (props) => {
//   return <AuthorizedLayout poweredBy={<PoweredByWrapper />} {...props} />
// }

// export default memo(AuthorizedLayoutWrapper)
