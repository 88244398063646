import React, { memo, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import HomeContainer from '../../../../containers/HomeContainer'
// @TODO: Remove if earn vir via x stopped
import {
  // EARN_VIA_X,
  STAKING_URL
} from '../../../../constants/navigation'
// import StakingContainer from './StakingContainer' // @TODO: Need to revert when staking ready
import StakingContainer from './WalletContainer'
import { getBannersGroupingData } from '../../../../store/actions/banners'
// import EarnViaXContainer from './EarnViaXContainer'

const HomeContainerWrapper = (props) => {
  const dispatch = useDispatch()
  const authId = useSelector(
    (state) => state.accountReducer.accountProfile.authId
  )
  useEffect(() => {
    if (authId) {
      dispatch(getBannersGroupingData())
    }
  }, [authId])

  return (
    <HomeContainer
      {...props}
      additionalRoutes={
        <>
          <Route path={STAKING_URL} element={<StakingContainer />} />
          {/* <Route path={EARN_VIA_X} element={<EarnViaXContainer />} /> */}
        </>
      }
    />
  )
}

export default memo(HomeContainerWrapper)
