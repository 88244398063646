import { getDefaultConfig } from '@rainbow-me/rainbowkit'
import { mainnet, sepolia } from 'wagmi/chains'

import { isProduction } from '../../../../libs/qb-brand-web-components'

const isEnvProd = isProduction()
const chains = [mainnet, sepolia]

const wagmiConfig = getDefaultConfig({
  appName: `vip${!isEnvProd ? '-testing' : ''}`,
  projectId: process.env.REACT_APP_WALLET_PROJECT_ID,
  chains,
  ssr: false
})

const getChain = () => {
  return isEnvProd ? mainnet : sepolia
}

export { wagmiConfig, getChain }
