import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTranslation } from 'react-i18next'
import { useAccount } from 'wagmi'
import { useNavigate } from 'react-router-dom'

import Wallet from '../components/wallet/Wallet'
import { HOME_URL } from '../../../../constants/navigation'
import { getWallet } from '../../../../store/actions/wallet'
import Loading from '../../../../components/shared/loading/Loading'
import { WALLET_ACCOUNT_STATUS } from '../utils/staking.helpers'

const WalletContainer = ({
  getWallet,
  walletProfile: { isProcessing, nfts }
}) => {
  const { t } = useTranslation()
  const { address, status } = useAccount()
  const navigate = useNavigate()
  useEffect(() => {
    if (status === WALLET_ACCOUNT_STATUS.CONNECTED && address) {
      getWallet(address)
    } else {
      navigate(HOME_URL, { replace: true })
    }
  }, [status, address])

  return isProcessing ? <Loading /> : <Wallet t={t} nfts={nfts} />
}

const mapStateToProps = ({ walletReducer }) => {
  const { walletProfile } = walletReducer
  return {
    walletProfile
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getWallet
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(WalletContainer)
