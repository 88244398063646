import React, { memo } from 'react'
import { useSelector } from 'react-redux'
// @TODO: Remove if earn vir via x stopped
// import { faSquareXTwitter } from '@fortawesome/free-brands-svg-icons'
import { faMegaphone } from '@fortawesome/pro-light-svg-icons'

import OverviewMainPanel from '../../../components/overview/OverviewMainPanel'
// @TODO: Enable when wallet connection is ready
// import WalletConnectButtonsWrapper from './utils/WalletConnectButtonsWrapper'
// @TODO: Need to revert when staking ready
import WalletConnectButtonsWrapper from './utils/WalletConnectButtonsWrapperWallet'
import OverviewNavPanel from '../../../components/overview/OverviewNavPanel'
// import { BWPageContent } from '../../../libs/qb-brand-web-components'
// import { EARN_VIA_X } from '../../../constants/navigation'

const OverviewMainPanelWrapper = ({ t, ...restProps }) => {
  const banners = useSelector(
    (state) => state.bannersReducer.bannersProfile.banners
  )
  const bannersCards = banners.map(
    ({ title, link, background, target }, index) => {
      const linkParams =
        target === 'blank'
          ? {
              as: 'a',
              href: link,
              target
            }
          : {
              to: link
            }
      return (
        <OverviewNavPanel
          key={index}
          icon={faMegaphone}
          label={title}
          iconCss={{ fontSize: 24 }}
          bgImage={background}
          customCss={{
            justifyContent: 'flex-start'
          }}
          headingCss={{
            alignItems: 'flex-start',
            flexDirection: 'row'
          }}
          titleCss={{
            textAlign: 'left'
          }}
          {...linkParams}
        />
      )
    }
  )

  // @TODO: Enable when wallet connection is ready
  return (
    <WalletConnectButtonsWrapper
      // earnVir={
      //   <OverviewNavPanel
      //     icon={faSquareXTwitter}
      //     label={t('brand:earn-vir.earn-via-x')}
      //     to={EARN_VIA_X}
      //     iconCss={{ fontSize: 30 }}
      //     {...defaultStyles}
      //   />
      // }
      banners={bannersCards}
    >
      <OverviewMainPanel
        t={t}
        {...restProps}
        customCss={{ gap: 15, padding: 15 }}
        panelCss={{ height: 'auto' }}
      />
    </WalletConnectButtonsWrapper>
  )

  // return (
  //   <BWPageContent css={{ paddingTop: 0 }}>
  //     {bannersCards}
  //     <OverviewMainPanel
  //       t={t}
  //       {...restProps}
  //       customCss={{ gap: 15, padding: 15 }}
  //       panelCss={{ height: 'auto' }}
  //     />
  //   </BWPageContent>
  // )
}

export default memo(OverviewMainPanelWrapper)
