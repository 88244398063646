import React, { memo } from 'react'
import { BWPanel, BWText } from 'src/libs/qb-brand-web-components'

import { ProviderCardContainer, ProviderLabel } from '../common'

const ProviderCard = ({
  label,
  subLabel,
  icon,
  onClick,
  anchor = true,
  labelSize = 18,
  labelGap = 10
}) => {
  return (
    <BWPanel gap={10} padding={15} onClick={onClick} anchor={anchor}>
      <ProviderCardContainer>
        {icon && icon}
        <ProviderLabel gap={labelGap}>
          <BWText label={label} size={labelSize} weight={700} />
          {subLabel && subLabel}
        </ProviderLabel>
      </ProviderCardContainer>
    </BWPanel>
  )
}

export default memo(ProviderCard)
