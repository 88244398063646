import React, { memo } from 'react'
import { BWPageContent } from 'src/libs/qb-brand-web-components'
import { faKey } from '@fortawesome/pro-light-svg-icons'
import { Link } from 'react-router-dom'

import BackButton from '../../../../../components/shared/button/BackButton'
import PageHeaderWrapper from 'wrappers/PageHeaderWrapper'
import { HOME_URL } from '../../../../../constants/navigation'
// import WalletDetails from './WalletDetails'
import NftCard from './NftCard'
import GetFounderKey from './GetFounderKey'

const Wallet = ({ t, nfts }) => {
  return (
    <>
      <BackButton
        t={t}
        to={HOME_URL}
        as={Link}
        styles={{ color: 'inherit', textDecoration: 'inherit' }}
      />
      <PageHeaderWrapper
        label={t('brand:wallet.qbx-founders-key')}
        icon={faKey}
        description={t('brand:wallet.qbx-founders-key-desc')}
        header
      />

      <BWPageContent>
        {/* <WalletDetails t={t} /> */}
        {nfts.length ? (
          nfts.map((nft, index) => <NftCard key={index} t={t} {...nft} />)
        ) : (
          <GetFounderKey t={t} />
        )}
      </BWPageContent>
    </>
  )
}

export default memo(Wallet)
