import React, { memo, useEffect, useState } from 'react'
import { faKey, faWallet } from '@fortawesome/pro-light-svg-icons'
import '@rainbow-me/rainbowkit/styles.css'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { useAccount } from 'wagmi'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import OverviewNavPanel from '../../../../components/overview/OverviewNavPanel'
import { STAKING_URL } from '../../../../constants/navigation'
import { isWalletConnected, WALLET_ACCOUNT_STATUS } from './staking.helpers'
import { CUSTOM_BRAND_ID } from '../../../../constants/pointsExchange'
import {
  connectExchangeProvider,
  updateExchangeProvider
} from '../../../../store/actions/exchange'
import { BWPageContent } from '../../../../libs/qb-brand-web-components'

const WalletConnectButtonsWrapper = ({ children, banners }) => {
  const { t } = useTranslation()
  const { address, status } = useAccount()
  const [walletClick, setWalletClick] = useState(false)
  const [initConnect, setInitConnect] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const qbxVipWalletMembershipFound = useSelector(
    (state) =>
      state.mainReducer.mainProfile.exchangeMemberships[
        CUSTOM_BRAND_ID.ETH_ADDRESS
      ]
  )

  useEffect(() => {
    if (status === WALLET_ACCOUNT_STATUS.CONNECTED && address && walletClick) {
      navigate(STAKING_URL)
    }
  }, [navigate, status, address, walletClick])

  useEffect(() => {
    if (initConnect && address) {
      if (qbxVipWalletMembershipFound && qbxVipWalletMembershipFound.id) {
        dispatch(
          updateExchangeProvider(
            qbxVipWalletMembershipFound.id,
            CUSTOM_BRAND_ID.ETH_ADDRESS,
            address
          )
        )
      } else {
        dispatch(connectExchangeProvider(CUSTOM_BRAND_ID.ETH_ADDRESS, address))
      }
    }
  }, [address, initConnect])

  const defaultStyles = {
    headingCss: {
      flexDirection: 'row'
    }
  }
  return (
    <BWPageContent css={{ paddingTop: 0 }}>
      <ConnectButton.Custom>
        {({
          account,
          chain,
          openAccountModal,
          openConnectModal,
          authenticationStatus,
          mounted
        }) => {
          const connected = isWalletConnected({
            account,
            chain,
            authenticationStatus,
            mounted
          })
          const handleConnectWallet = () => {
            setWalletClick(false)
            if (connected) {
              openAccountModal()
            } else {
              openConnectModal()
              setInitConnect(true)
            }
          }
          return (
            <OverviewNavPanel
              icon={faWallet}
              label={
                connected
                  ? account.displayName
                  : t('brand:staking.connect-wallet')
              }
              onClick={handleConnectWallet}
              iconCss={{ fontSize: 18 }}
              {...defaultStyles}
            />
          )
        }}
      </ConnectButton.Custom>
      {banners}
      {children}
      <ConnectButton.Custom>
        {({
          account,
          chain,
          openConnectModal,
          authenticationStatus,
          mounted
        }) => {
          const connected = isWalletConnected({
            account,
            chain,
            authenticationStatus,
            mounted
          })
          const handleWalletClick = () => {
            openConnectModal()
            setWalletClick(true)
            if (!connected) {
              setInitConnect(true)
            }
          }
          return (
            <OverviewNavPanel
              icon={faKey}
              label={t('brand:wallet.qbx-founders-key')}
              to={connected ? STAKING_URL : null}
              onClick={!connected ? handleWalletClick : null}
              iconCss={{ fontSize: 24 }}
              {...defaultStyles}
            />
          )
        }}
      </ConnectButton.Custom>
    </BWPageContent>
  )
}

export default memo(WalletConnectButtonsWrapper)
