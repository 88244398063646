import moment from 'moment'
import { isProduction } from '../../../../libs/qb-brand-web-components'
import { FOUNDER_KEYS, MEMBER_TIERS, MEMBER_TIERS_KEY } from '../constants/app'
import { getNftCoverImage } from '../../../../util/nft.helpers'

const isWalletConnected = ({
  mounted,
  authenticationStatus,
  account,
  chain
}) => {
  const ready = mounted && authenticationStatus !== 'loading'
  return (
    ready &&
    account &&
    chain &&
    (!authenticationStatus || authenticationStatus === 'authenticated')
  )
}

const WALLET_ACCOUNT_STATUS = {
  CONNECTED: 'connected'
}

const disconnectMetamaskWallet = () => {
  localStorage.setItem('wagmi.io.metamask.disconnected', true)
  localStorage.setItem('wagmi.metaMask.disconnected', true)
}

const nextClaimTime = () => {
  const currentDate = moment.utc()

  // Get the next Wednesday at 2 PM UTC
  let nextWeek = moment
    .utc()
    .day('Wednesday')
    .hour(14)
    .minute(0)
    .second(0)
    .millisecond(0)
  if (nextWeek.isBefore(currentDate)) {
    nextWeek = nextWeek.add(7, 'days')
  }

  // Calculate the time delta
  return formatTimeDelta(nextWeek.diff(currentDate))
}

const formatTimeDelta = (timeDelta) => {
  // Convert milliseconds to days, hours, and minutes
  const days = Math.floor(timeDelta / (1000 * 60 * 60 * 24))
  const hours = Math.floor(
    (timeDelta % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
  const minutes = Math.floor((timeDelta % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((timeDelta % (1000 * 60)) / 1000)

  // Format the result
  return `${days}d ${hours}h ${minutes}m ${seconds}s`
}

const getClaimDashboardUrl = () => {
  return `https://tc${isProduction() ? '' : 'test'}.qiibeefoundation.org`
}

export const WALLET_ERRORS = {
  USER_REJECTED_REQUEST: 'User rejected the request.'
}

const buildTransactionErrorMessage = (message) => {
  return message === WALLET_ERRORS.USER_REJECTED_REQUEST
    ? 'brand:staking.rejected-transaction-request'
    : message || 'error.something-went-wrong'
}

const getMemberTierMultiplierValue = (tier) => {
  return MEMBER_TIERS[tier] || 0
}

const getNftMultiplierValue = (nftCount) => {
  if (nftCount >= 1 && nftCount <= 5) {
    return FOUNDER_KEYS['1-5']
  } else if (nftCount >= 6 && nftCount <= 20) {
    return FOUNDER_KEYS['6-20']
  } else if (nftCount >= 21 && nftCount <= 50) {
    return FOUNDER_KEYS['21-50']
  } else if (nftCount >= 51 && nftCount <= 100) {
    return FOUNDER_KEYS['51-100']
  } else if (nftCount > 100) {
    return FOUNDER_KEYS['100+']
  }
  return 0
}

// @TODO: Change hard coding
const TIER_MULTIPLIER_MAP = isProduction()
  ? {
      1: MEMBER_TIERS_KEY.ASPIRING,
      5: MEMBER_TIERS_KEY.FAN,
      10: MEMBER_TIERS_KEY.INFLUENCER,
      15: MEMBER_TIERS_KEY.CHAMPION,
      20: MEMBER_TIERS_KEY.AMBASSADOR,
      25: MEMBER_TIERS_KEY.HONORARY
    }
  : {
      1: MEMBER_TIERS_KEY.ASPIRING,
      5: MEMBER_TIERS_KEY.FAN,
      10: MEMBER_TIERS_KEY.INFLUENCER,
      15: MEMBER_TIERS_KEY.CHAMPION,
      20: MEMBER_TIERS_KEY.AMBASSADOR,
      25: MEMBER_TIERS_KEY.HONORARY
    }

const getMemberTierMultiplierKey = (nfts) => {
  const nft = nfts.find((nft) => TIER_MULTIPLIER_MAP[nft.nftId])
  return nft
    ? {
        tier: TIER_MULTIPLIER_MAP[nft.nftId],
        icon: getNftCoverImage(nft?.metadata)
      }
    : {
        tier: MEMBER_TIERS_KEY.ASPIRING
      }
}

export {
  isWalletConnected,
  WALLET_ACCOUNT_STATUS,
  disconnectMetamaskWallet,
  nextClaimTime,
  getClaimDashboardUrl,
  buildTransactionErrorMessage,
  getMemberTierMultiplierValue,
  getNftMultiplierValue,
  getMemberTierMultiplierKey
}
